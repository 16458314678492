import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { StorageService } from 'shared/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor(private router: Router, private storageService: StorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!localStorage.getItem('user')) {
      if (this.router.url !== '/forgotPassword' && this.router.url !== '/login') {
        return throwError(new HttpErrorResponse({ status: 401 }));
      }
    }
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem("user")).token : ''}`
      }
    });
    return next.handle(request);
  }
}
