import { Injectable } from '@angular/core';
import { ApiService } from 'shared/services/api/api.service';
import {environment} from './../../../../environments/environment';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  apiUrl = environment.api;

  constructor(private apiService: ApiService, private router: Router) { }

  getLangByLang(lang1: string): Observable<object> {
    return this.apiService.get(`${this.apiUrl}language/all?i18n=${lang1}`);
  }
  getLangByLangCategory(lang1: string,category: string): Observable<object> {
    return this.apiService.get(`${this.apiUrl}language/all?i18n=${lang1}&category=${category}`);
  }
  getLangByKeyLang(lang1: string,key: string): Observable<object> {
    return this.apiService.get(`${this.apiUrl}language/all?i18n=${lang1}&key=${key}`);
  }
  saveOrUpdate(data: any): Observable<object> {
    return this.apiService.post(`${this.apiUrl}language/saveOrUpdate`, data);
  }
}
