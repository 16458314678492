import { Component } from '@angular/core';
import { LangService } from 'lang/services/lang.services';
import { Router } from '@angular/router';
import { MessageService } from 'shared/services/message/message.service';

@Component({
  selector: 'lang-form',
  templateUrl: './lang-form.component.html',
  styleUrls: ['./lang-form.component.scss']
})
export class LangFormComponent {
  title = 'invitgo-i18n';
  lang1 = 'en-us';
  lang2 = 'en-us';
  languages1=[];  
  categories1=[];
  languages2=[];  
  categories2=[];
  langSave:any=null;
  
  
  
  
  
  constructor(private langService: LangService, private router: Router, private messageService: MessageService){
    this.changeLang1();
    this.changeLang2();
    
    /*
    if(this.langSave){
      for(var key in this.langSave) { 
        let item = {
          key: key,
          value: this.langSave[key]
        }
        this.saveOrUpdate(item,this.lang1);
      }
    }
    */
  }
  groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  changeLang1(){
    this.langService.getLangByLangCategory(this.lang1,'InvitGo').subscribe((res:any[])=>{
      this.languages1 = this.groupBy(res,'category');
      this.categories1 = Object.keys(this.languages1);
    }, (error)=>{
      this.messageService.genericError();
    });
  }
  changeLang2(){
    this.langService.getLangByLangCategory(this.lang2,'InvitGo').subscribe((res:any[])=>{
      this.languages2 = this.groupBy(res,'category');
      this.categories2 = Object.keys(this.languages2);
    }, (error)=>{
      this.messageService.genericError();
    });
  }
  saveOrUpdate(data:any,lang:any){
    this.langService.saveOrUpdate({i18n:lang,item: data}).subscribe((res:any)=>{
      this.messageService.showSuccess('changes saved successfully', lang);
    }, (error)=>{
      this.messageService.genericError();
    });
  }
}
