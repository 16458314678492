import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppComponent } from './app.component';
import { SecurityLoginComponent } from './modules/security/components/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from 'security/services/auth.service';
import { RouterModule, Routes } from '@angular/router';
import { LangFormComponent } from 'lang/components/lang-form/lang-form.component';
import { LangService } from 'lang/services/lang.services';
import { ErrorService } from 'shared/handlers/error.service';
import { TokenInterceptor } from 'security/interceptors/token.interceptor';
import { AuthGuard } from 'security/guards/auth.guard';
import { NoAuthGuard } from 'security/guards/noauth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxEditorModule } from 'ngx-editor';
import { EmailLangComponent } from 'lang/components/email-lang/email-lang.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

const appRoutes: Routes = [
  {
    path: 'login',
    canActivate: [NoAuthGuard],
    component: SecurityLoginComponent
  },
  {
    path: '',
    component: LangFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'email',
    component: EmailLangComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**', 
    component: LangFormComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    AppComponent,
    SecurityLoginComponent,
    LangFormComponent,
    EmailLangComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularEditorModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(
      appRoutes
    )
  ],
  providers: [AuthService, LangService, { provide: ErrorHandler, useClass: ErrorService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthGuard,
    NoAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
