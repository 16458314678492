import { Injectable } from '@angular/core';
import { ApiService } from 'shared/services/api/api.service';
import {environment} from './../../../../environments/environment';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from 'security/dto/user-dto';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.api;
  
  isLoggedInSubject = new BehaviorSubject<boolean>(this.hasToken());

  userSubject = new BehaviorSubject<User>( JSON.parse(localStorage.getItem("user")) );

  constructor(private apiService: ApiService, private router: Router) { }

  login(data: any): Observable<object> {
    return this.apiService.post(`${this.apiUrl}auth/loginI18n`, data);
  }

  logout():void {
    localStorage.removeItem("user");
    this.isLoggedInSubject.next(false);
  }

  hasToken():boolean {
    return !!localStorage.getItem("user");
  }

  isLoggedIn(): Observable<boolean> {
    return this.isLoggedInSubject.asObservable();
  }


  setUser(data: User){
    localStorage.setItem("user", JSON.stringify(data));
    return this.userSubject.next(data);
  }

  getUser(): User{
    return this.userSubject.getValue();
  }

  get user(){
    return this.userSubject.getValue();
  }



}
