import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private localStorage: LocalStorage) { }

  async get(key: string) {
    return this.localStorage.getItem(key);
  }
  async saveOrUpdate(key: string, value: any) {
    return this.localStorage.setItem(key, value);
  }
  async delete(key: string) {
    return this.localStorage.removeItem(key);
  }
  async clear() {
    return this.localStorage.clear();
  }
}
