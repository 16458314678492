import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'security/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private securityService: AuthService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
            if (!localStorage.getItem("user") || !JSON.parse(localStorage.getItem("user")).token) {
                this.router.navigateByUrl("/login");
            }
            return !! (localStorage.getItem("user") || JSON.parse(localStorage.getItem("user")).token) ;
        }
    
    
}
