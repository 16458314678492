import { Injectable, ErrorHandler, EventEmitter } from '@angular/core';
import { MessageService } from 'shared/services/message/message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BusinessException } from 'shared/dto/business-error';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ErrorService implements ErrorHandler {
    static eventError: EventEmitter<any> = new EventEmitter();
    constructor(private messageService: MessageService) { }
    handleError(error: any) {
        ErrorService.eventError.emit(error);
        if (error instanceof HttpErrorResponse) {
            //Backend returns unsuccessful response codes such as 404, 500 etc.
            if (error.status === 400) {
                // this.messageService.warning(error.message);
            } else if (error.status === 500) {
                //this.messageService.error('Error General');
            }
        } else if (error instanceof BusinessException) {
            //this.messageService.warning(error.message);
        } else {
            //this.messageService.error('Error General');
        }
    }
}
