import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'security-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class SecurityLoginComponent {
  title = 'invitgo-i18n';
  
  loginForm: FormGroup = new FormGroup({
    email: new FormControl(null, Validators.compose([
      Validators.required, 
      Validators.email
    ])),
    password: new FormControl(null, Validators.compose([
      Validators.required,
      Validators.minLength(4), 
      Validators.maxLength(12)
    ])),

  });


  constructor(private authService: AuthService, private router: Router){
    
  }

  login(){
    this.authService.login({
      username: this.loginForm.get("email").value,
      password: this.loginForm.get("password").value
    }).subscribe((res:any)=>{
      this.authService.setUser(res);
      this.router.navigateByUrl('/');
    }, (error)=>{
      this.loginForm.controls.password.setErrors({incorrect: true});
    });
  }

}
