import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private http: HttpClient) { }


  get<T>(url: string, params?: HttpParams): Observable<T> {
    return this.http.get(url, { params: params })
      .pipe(map((response: T) => {
        return response;
      }));
  }

  post<T>(url: string, data: any, params?: HttpParams): Observable<T> {
    return this.http.post(url, data)
      .pipe(map((response: T) => {
        return response;
      }));
  }

  put<T>(url: string, data?: any): Observable<T> {
    return this.http.put(url, data, {})
      .pipe(map((response: T) => {
        return response;
      }));
  }

  delete<T>(url: string, params?: HttpParams): Observable<T> {
    return this.http.delete(url, { params: params })
      .pipe(map((response: T) => {
        return response;
      }));
  }

  getBlob(url: string, params?: HttpParams): Observable<any> {
    return this.http.get(url, { params: params, responseType: 'blob' })
      .pipe(map(response => {
        return response;
      }));
  }

}
