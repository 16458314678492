import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

constructor(private toastr: ToastrService) { }

  showSuccess(text, title) {
    this.toastr.success(text, title, {
      timeOut: 3000
    });
  }
  genericError() {
    this.toastr.error('an error occurred while saving', 'error', {
      timeOut: 3000
    });
  }
}
